import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Headers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hamburger: false,
            language: localStorage.getItem("langCode")
        }
    }


    openHamburger = () => {
        this.setState({
            hamburger: true
        });

    }

    closeHamburger = () => {
        this.setState({
            hamburger: false
        });
    }


    setLanguage = (e) => {
        localStorage.setItem("langCode", e);
        window.location.reload();
    }



    render() {
        const { data } = this.props;

        return (
            <>
                <header>
                    <div className="left">
                        <div className="social">
                            <div className="item">
                                <a rel="noopener noreferrer" target="_blank" href={data.header.links.facebook}>
                                    <img src="Assets/img/headerFacebook.png" alt="facebook" />
                                </a>
                            </div>
                            <div className="item">
                                <a rel="noopener noreferrer" target="_blank" href={data.header.links.twitter}>
                                    <img src="Assets/img/headerTwitter.png" alt="Twitter" />
                                </a>
                            </div>
                            <div className="item">
                                <a rel="noopener noreferrer" target="_blank" href={data.header.links.instagram}>
                                    <img src="Assets/img/headerInstagram.png" alt="Instagram" />
                                </a>
                            </div>
                            <div className="item">
                                <a rel="noopener noreferrer" target="_blank" href={data.header.links.youtube}>
                                    <img src="Assets/img/headerYoutube.png" alt="Youtube" />
                                </a>
                            </div>
                        </div>

                        <div onClick={this.openHamburger.bind(this)} className={this.state.hamburger ? "hamburger open" : "hamburger closed"}>
                            <div className="burger-main">
                                <div className="burger-inner">
                                    <span className="top"></span>
                                    <span className="mid"></span>
                                    <span className="bot"></span>
                                </div>
                            </div>
                            <div className="path-burger">
                                <div className="animate-path">
                                    <div className="path-rotation"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="middle"><Link to="/"><img src="Assets/img/wst-turkish-masters.svg" alt="logo" /></Link></div>
                    <div className="right">
                        <div className="language">
                            <div className="item"><img src="Assets/img/language.png" alt="icon" /></div>
                            <div className="item"><div onClick={this.setLanguage.bind(this, "en")} className={this.state.language === "en" ? "" : "passive"}  >ENG</div></div>
                            <div className="item"><div onClick={this.setLanguage.bind(this, "tr")} className={this.state.language === "tr" ? "" : "passive"}>TR</div></div>
                        </div>
                    </div>

                    <div className={this.state.hamburger ? "menu active" : "menu"}>
                        <div className="left">
                            <div className="close" onClick={this.closeHamburger.bind(this)}>
                                <img src="Assets/img/close.svg" alt="icon" />
                            </div>
                            <div className="menuSocial">
                                <div className="mSocialIcon">
                                    <a rel="noopener noreferrer" target="_blank" href={data.header.links.facebook}>
                                        <img src="Assets/img/facebook_white.svg" alt="icon" />
                                    </a>
                                </div>
                                <div className="mSocialIcon">
                                    <a rel="noopener noreferrer" target="_blank" href={data.header.links.instagram}>
                                        <img src="Assets/img/instagram_white.svg" alt="icon" />
                                    </a>
                                </div>
                                <div className="mSocialIcon">
                                    <a rel="noopener noreferrer" target="_blank" href={data.header.links.twitter}>
                                        <img src="Assets/img/twitter_white.svg" alt="icon" />
                                    </a>
                                </div>
                                <div className="mSocialIcon">
                                    <a rel="noopener noreferrer" target="_blank" href={data.header.links.youtube}>
                                        <img src="Assets/img/youtube_white.svg" alt="icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="rigth">
                            <div className="menuList">
                                <div className="languages">
                                    <ul>
                                        <li onClick={this.setLanguage.bind(this, "tr")} className={this.state.language === "tr" ? "active" : ""}>
                                            TR
                                        </li>
                                        <li onClick={this.setLanguage.bind(this, "en")} className={this.state.language === "en" ? "active" : ""}>
                                            EN
                                        </li>

                                    </ul>
                                </div>
                                <div className="MenuBottom">
                                    <div className="middleMenuText">
                                        <ul>
                                            <li>
                                                <Link to="/MeetSnookers" onClick={this.closeHamburger.bind(this)}>{data.header.menu[this.state.language].menu1}</Link>
                                            </li>
                                            <li>
                                                <Link to="/Tickets" onClick={this.closeHamburger.bind(this)}> {data.header.menu[this.state.language].menu2}</Link>
                                            </li>
                                            <li>
                                                <Link to="/CosmosPerformanceHall" onClick={this.closeHamburger.bind(this)}> {data.header.menu[this.state.language].menu3}</Link>
                                            </li>
                                            <li>
                                                <Link to="/FANZONE" onClick={this.closeHamburger.bind(this)}> {data.header.menu[this.state.language].menu4}</Link>
                                            </li>
                                            <li>
                                                <Link to="/Gallery" onClick={this.closeHamburger.bind(this)}> {data.header.menu[this.state.language].menu5}</Link>
                                            </li>
                                            <li>
                                                <Link to="/Sponsors" onClick={this.closeHamburger.bind(this)}> {data.header.menu[this.state.language].menu6}</Link>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </header>
            </>
        );
    }
}
export default Headers;