import React, { Component } from 'react';


import Menu from './Menu';


class Sponsors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem("langCode")
        }
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }







    render() {
        const { data } = this.props;
        return (
            <main>
                <div className="meetSnookersParent">
                    <Menu data={data} />
                    <div className="meetSnookers">
                        <div className='title'>
                            <div className="text">{data.sponsors[this.state.language].title}</div>
                        </div>
                        <div className='contents sponsors'>
                            <div className='mainSponsor'>
                                <a rel="noopener noreferrer" target="_blank" href="https://www.nirvanahotels.com.tr/">
                                    <img src="/Assets/img/nirvanaLogo.png" />
                                </a>
                            </div>
                            <div className='subSponsors'>
                                <div className='item'>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.galerikristal.com.tr/">
                                        <img src="/Assets/img/galeriKristalLogo.png" alt="logo" />
                                    </a>
                                </div>
                                <div className='item'>
                                    <a rel="noopener noreferrer" target="_blank" href="https://kilitglobal.com/">
                                        <img src="/Assets/img/kilitglobalLogo.png" />
                                    </a>
                                </div>
                                <div className='item'>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.aryildiz.com/">
                                        <img src="/Assets/img/aryildizLogo.png" />
                                    </a>
                                </div>
                                <div className='item'>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.crystal.com.tr/">
                                        <img src="/Assets/img/crystalEnLogo.png" />
                                    </a>
                                </div>
                                <div className='item'>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.maxstyle.com.tr/">
                                        <img src="/Assets/img/maxStyle.png" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        );
    }
}
export default Sponsors