import React, { Component } from 'react';
import { Link } from "react-router-dom";
export default class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
         
            language: localStorage.getItem("langCode")
        }
    }
    render() {
        const { data } = this.props;
        return (<div className="menuDesktop">
            <ul>
                <li><Link to="/MeetSnookers">{data.header.menu[this.state.language].menu1}</Link></li>
                <li><Link to="/Tickets">{data.header.menu[this.state.language].menu2}</Link></li>
                <li><Link to="/CosmosPerformanceHall">{data.header.menu[this.state.language].menu3}</Link></li>
                <li><Link to="/Fanzone">{data.header.menu[this.state.language].menu4}</Link></li>
                <li><Link to="/Gallery">{data.header.menu[this.state.language].menu5}</Link></li>
                <li><Link to="/Sponsors">{data.header.menu[this.state.language].menu6}</Link></li>
            </ul>
        </div>);
    }
}
