import React, { Component } from 'react';
import Menu from './Menu';


class CosmosPerformanceHall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem("langCode")
        }
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const { data } = this.props;
        return (
            <main>
                <div className="meetSnookersParent">
                    <Menu data={data} />
                    <div className="meetSnookers">
                        <div className='title'>
                            <div className="text">{data.cosmos[this.state.language].seatingPlan}</div>
                        </div>
                        <div className='contents seats mb30'>
                            <div className='seatPlans'>
                                <img src="./Assets/img/seatingNew1.png" />
                            </div>
                            <div className='seatPlansText'>
                                <div className='mainTitle'>{data.cosmos[this.state.language].title1}</div>
                                <div className='groups'>
                                    <div className='title'>{data.cosmos[this.state.language].block1a.title} - </div>
                                    <div className='text'>{data.cosmos[this.state.language].block1a.text}</div>
                                </div>
                                <div className='groups'>
                                    <div className='title'>{data.cosmos[this.state.language].block2a.title} - </div>
                                    <div className='text'>{data.cosmos[this.state.language].block2a.text}</div>
                                </div>
                                <div className='groups'>
                                    <div className='title'>{data.cosmos[this.state.language].block3a.title} - </div>
                                    <div className='text'>{data.cosmos[this.state.language].block3a.text}</div>
                                </div>
                                <div className='groups'>
                                    <div className='title'>{data.cosmos[this.state.language].block4a.title} - </div>
                                    <div className='text'>{data.cosmos[this.state.language].block4a.text}</div>
                                </div>
                                <div className='groups'>
                                    <div className='title'>{data.cosmos[this.state.language].block5a.title} - </div>
                                    <div className='text'>{data.cosmos[this.state.language].block5a.text}</div>
                                </div>
                                <div className='groups'>
                                    <div className='title'>{data.cosmos[this.state.language].block6a.title} - </div>
                                    <div className='text'>{data.cosmos[this.state.language].block6a.text}</div>
                                </div>
                
                            </div>
                        </div>
                        <div className='contents seats '>
                            <div className='seatPlans'>
                                <img src="./Assets/img/seatingNew2.png" />
                            </div>
                            <div className='seatPlansText'>
                            <div className='mainTitle'>{data.cosmos[this.state.language].title2}</div>
                                <div className='groups'>
                                    <div className='title'>{data.cosmos[this.state.language].block1b.title} - </div>
                                    <div className='text'>{data.cosmos[this.state.language].block1b.text}</div>
                                </div>
                                <div className='groups'>
                                    <div className='title'>{data.cosmos[this.state.language].block2b.title} - </div>
                                    <div className='text'>{data.cosmos[this.state.language].block2b.text}</div>
                                </div>
                                <div className='groups'>
                                    <div className='title'>{data.cosmos[this.state.language].block3b.title} - </div>
                                    <div className='text'>{data.cosmos[this.state.language].block3b.text}</div>
                                </div>
                               

                            </div>
                        </div>
                    </div>
                </div>

            </main>

        );
    }
}
export default CosmosPerformanceHall