import React, { Component } from 'react';

import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css"
import Menu from './Menu';


class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem("langCode")
        }
    }
    componentDidMount() {
        const swiper2 = new SwiperCore(".gallerySwiper", {
            modules: [Autoplay, Navigation, Pagination],
            grabCursor: true,
            watchSlidesProgress: true,
            loop: true,
            loopedSlides: 10,
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
            },
            autoplay: {
                delay: 5000,
            },


        });

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }







    render() {
        const { data } = this.props;
        return (
            <main>
                <div className="meetSnookersParent yesBg">
                    <Menu data={data} />
                    <div className="meetSnookers noBg">
                        <div className='title'>
                            <div className="text">{data.gallery[this.state.language].titleEvent}</div>
                        </div>
                        <div className='contents gallery'>
                            <div className='swiper gallerySwiper'>
                                <div className="swiper-wrapper">
                                    {data.gallery.imgEvent.map((item, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <img src={item.address} alt="Gallery" />
                                        </div>
                                    ))
                                    }

                                </div>
                                <div className="swiper-pagination"></div>
                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                            </div>
                        </div>
                    </div>
                    <div className="meetSnookers noBg pt0">
                        <div className='title'>
                            <div className="text">{data.gallery[this.state.language].titleFacilities}</div>
                        </div>
                        <div className='contents gallery'>
                            <div className='swiper gallerySwiper'>
                                <div className="swiper-wrapper">
                                    {data.gallery.imgFac.map((item, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <img src={item.address} alt="Gallery" />
                                        </div>
                                    ))
                                    }
                                </div>
                                <div className="swiper-pagination"></div>
                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        );
    }
}
export default Gallery