import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="item">
                    <a rel="noopener noreferrer" target="_blank" href="https://wst.tv/">
                        <img src="Assets/img/footer01logo.png" alt="logo" />
                    </a>
                </div>
                <div className="item">
                <a rel="noopener noreferrer" target="_blank" href="https://www.bilardo.gov.tr/">
                    <img src="Assets/img/footer02logo.png" alt="logo" />
                    </a>
                </div>
                <div className="item">
                <a rel="noopener noreferrer" target="_blank" href="https://www.nirvanahotels.com.tr/">
                    <img src="Assets/img/footer03logo.png" alt="logo" />
                    </a>
                </div>
                <div className="item">
                <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/bigbreakpromo">
                    <img src="Assets/img/footer04logo.png" alt="logo" />
                    </a>
                </div>
                <div className="item">
                <a rel="noopener noreferrer" target="_blank" href="https://www.sportoto.gov.tr/">
                    <img src="Assets/img/footer05logo.png" alt="logo" />
                    </a>
                </div>
                <div className="item">
                <a rel="noopener noreferrer" target="_blank" href="https://tga.gov.tr/home/">
                    <img src="Assets/img/footer06logo.png" alt="logo" />
                    </a>
                </div>
                <div className="item">
                <a rel="noopener noreferrer" target="_blank" href="https://gsb.gov.tr/anasayfa.html">
                    <img src="Assets/img/footer07logo.png" alt="logo" />
                    </a>
                </div>
                <div className="item">
                <a rel="noopener noreferrer" target="_blank" href="https://www.ktb.gov.tr/?_dil=2">
                    <img src="Assets/img/footer08logo.png" alt="logo" />
                    </a>
                </div>
                <div className="item">
                <a rel="noopener noreferrer" target="_blank" href="https://www.antalya.bel.tr/">
                    <img src="Assets/img/footer09logo.png" alt="logo" />
                    </a>
                </div>
            </footer>
        );
    }
}
export default Footer;