import React, { Component } from 'react';

import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/swiper.min.css';
import Menu from './Menu';


class MeetSnookers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem("langCode")
        }
    }

    componentDidMount() {
        const sliderEl = document.querySelector('.carousel-slider');

        this.createCarouselSlider(sliderEl)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    createInfoSlider = () => {
        /* var 
           */
    }


    createCarouselSlider = (el) => {
        // main swiper el
        const swiperEl = el.querySelector('.swipers');
        const swiper2 = new SwiperCore(".personalInfo", {
            spaceBetween: 10,
            simulateTouch: false

        });
        // init main swiper
         new SwiperCore(swiperEl, {
            modules: [Autoplay, Navigation, Pagination],
            grabCursor: true,
            watchSlidesProgress: true,
            loop: true,
            loopedSlides: 10,
            slidesPerView: 'auto',
            centeredSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            pagination: {
                el: '.swiper-pagination',
            },
            autoplay: {
                delay: 5000,
            },
            on: {
                slideChange(swiper) {
                   
                    swiper2.slideTo(swiper.realIndex);
                },
                // eslint-disable-next-line
                progress(swiper) {
                    const scaleStep = 0.2;
                    const zIndexMax = swiper.slides.length;
                    for (let i = 0; i < swiper.slides.length; i += 1) {
                        const slideEl = swiper.slides[i];
                        const slideProgress = swiper.slides[i].progress;
                        const absProgress = Math.abs(slideProgress);
                        let modify = 1;
                        if (absProgress > 1) {
                            modify = (absProgress - 1) * 0.3 + 1;
                        }
                        const opacityEls = slideEl.querySelectorAll(
                            '.carousel-slider-animate-opacity',
                        );
                        const translate = `${slideProgress * modify * 50}%`;
                        const scale = 1 - absProgress * scaleStep;
                        const zIndex = zIndexMax - Math.abs(Math.round(slideProgress));
                        slideEl.style.transform = `translateX(${translate}) scale(${scale})`;
                        slideEl.style.zIndex = zIndex;
                        if (absProgress > 3) {
                            slideEl.style.opacity = 0;
                        } else {
                            slideEl.style.opacity = 1;
                        }

                        opacityEls.forEach((opacityEl) => {
                            opacityEl.style.opacity = 1 - absProgress / 3;
                        });
                    }
                },
                // eslint-disable-next-line
                setTransition(swiper, duration) {
                    for (let i = 0; i < swiper.slides.length; i += 1) {
                        const slideEl = swiper.slides[i];
                        const opacityEls = slideEl.querySelectorAll(
                            '.carousel-slider-animate-opacity',
                        );
                        slideEl.style.transitionDuration = `${duration}ms`;
                        opacityEls.forEach((opacityEl) => {
                            opacityEl.style.transitionDuration = `${duration}ms`;
                        });
                    }
                },
            },
        });






        //return swiper;
    }


    downloadFile=(e)=> {
  
        window.open(e, '_blank');
    }

    render() {
        const { data } = this.props;
      
        return (
            <main>
                <div className="meetSnookersParent">
                    <Menu data={data} />
                    <div className="meetSnookers">
                        <div className='title'>
                            <div className="text">{data.meetPlayer[this.state.language].title}</div>
                        </div>
                        <div className='contents'>
                            <div className="carousel-slider">
                                <div className="swipers">
                                    <div className="swiper-wrapper">
                                        {data.meetPlayer[this.state.language].item.map((items, index) => (
                                            <div className="swiper-slide" key={index}>
                                                <div className="carousel-slider-animate-opacity">
                                                    <div className="circleImg">
                                                        <img src={items.avatar} alt={items.name} />
                                                    </div>
                                                    <div className="slide-content">
                                                        <div className="line"></div>
                                                        <h2>{items.name}</h2>
                                                    </div>
                                                    <div className='bg'></div>
                                                </div>
                                            </div>
                                        ))
                                        }

                                    </div>
                                    <div className="swiper-button-prev"></div>
                                    <div className="swiper-button-next"></div>
                                    <div className="swiper-pagination"></div>
                                </div>
                            </div>
                            <div className='personalInfo swiper'>
                                <div className="swiper-wrapper">
                                    {data.meetPlayer[this.state.language].item.map((items, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className='players'>
                                                <div className='imgs'>
                                                    <img src={items.image} alt={items.name} />
                                                </div>
                                                <div className='text'>
                                                    <div className='name'>{items.name}</div>
                                                    <div className='title'>{items.title}</div>
                                                    <div className='spect'>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].nationality_lng}</div>
                                                            <div className='values'>{items.nationality}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].turned_pro_lng}</div>
                                                            <div className='values'>{items.turned_pro}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].highest_tour_break_lng}</div>
                                                            <div className='values'>{items.highest_tour_break}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].location_lng}</div>
                                                            <div className='values'>{items.location}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].dob_lng}</div>
                                                            <div className='values'>{items.dob}</div>
                                                        </div>

                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].money_lng}</div>
                                                            <div className='values'>{items.money}</div>
                                                        </div>

                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].nickname_lng}</div>
                                                            <div className='values'>{items.nickname}</div>
                                                        </div>
                                                    </div>
                                                    <div className='title'>{data.meetPlayer[this.state.language].sessions_lng}</div>
                                                    <div className='spect'>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].frames_played_lng}</div>
                                                            <div className='values'>{items.sessions.frames_played}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].frames_won_lng}</div>
                                                            <div className='values'>{items.sessions.frames_won}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].frames_lost_lng}</div>
                                                            <div className='values'>{items.sessions.frames_lost}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].frames_won_per_lng}</div>
                                                            <div className='values'>{items.sessions.frames_won_per}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].shots_played_lng}</div>
                                                            <div className='values'>{items.sessions.shots_played}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].shots_played_lng}</div>
                                                            <div className='values'>{items.sessions.shots_played}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].shots_per_frame_lng}</div>
                                                            <div className='values'>{items.sessions.shots_per_frame}</div>
                                                        </div>

                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].breaks_over50_lng}</div>
                                                            <div className='values'>{items.sessions.breaks_over50}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].breakrate50_lng}</div>
                                                            <div className='values'>{items.sessions.breakrate50}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].breakover100_lng}</div>
                                                            <div className='values'>{items.sessions.breakover100}</div>
                                                        </div>
                                                        <div className='spectItem'>
                                                            <div className='keys'>{data.meetPlayer[this.state.language].breakrate100_lng}</div>
                                                            <div className='values'>{items.sessions.breakrate100}</div>
                                                        </div>
                                                    </div>
                                                    <div className='wins'>{data.meetPlayer[this.state.language].wins_lng} : {items.sessions.wins} </div>

                                                </div>
                                            </div>
                                            <div className='titleCar'>{data.meetPlayer[this.state.language].Career_highlights_lng}</div>
                                            <div className='carDetail' dangerouslySetInnerHTML={{ __html: items.Career_highlights }}></div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rule">
                    <div className="ruleTitle">
                        <div className="text">{data.meetPlayer[this.state.language].rules}</div>
                    </div>
                    <div className="rules">
                        <div className="title">{data.meetPlayer[this.state.language].rulesText}</div>
                        <div className="ruleDocument" onClick={this.downloadFile.bind(this,data.meetPlayer[this.state.language].downloadLink)}>
                            {data.meetPlayer[this.state.language].downloadFile}
                        </div>
                    </div>
                </div>
            </main>

        );
    }
}
export default MeetSnookers