import React, { Component } from 'react';
import FlipClock from 'flipclock';
import "flipclock/dist/flipclock.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/swiper.min.css';
import Menu from './Menu';
import axios from 'axios';
import moment from 'moment';

import { Link } from "react-router-dom";

SwiperCore.use([Navigation, Autoplay, Pagination])

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem("langCode"),
            name: "",
            email: "",
            message: "",
            nameError: false,
            emailError: false,
            messageError: false,
            sendLoading: false,
            sendStatus: 0
        }
    }

    componentDidMount() {
        const el = document.querySelector('#clock');

        const end = new Date(2022, 2, 7);
        const clock = new FlipClock(
            el, end, {
            face: 'DayCounter',
            countdown: true,
            autoStart: true,
            language: this.state.language,

        });


        clock.on('interval', () => {
            //  console.log('interval', clock.value);
        });

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }

    setContact = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            nameError: false,
            emailError: false,
            messageError: false
        })
    }


    validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    sendMessage = async () => {

        if (this.state.name.length < 3) {

            this.setState({
                nameError: true,
            })

        } else if (!this.validateEmail(this.state.email)) {

            this.setState({
                emailError: true,
            })

        } else if (this.state.message.length < 3) {
            this.setState({
                messageError: true,
            })

        } else {

            this.setState({
                sendLoading: true,
            })

            axios.defaults.headers.common["Guid"] = window.btoa(moment().format('DD.MM.YYYY'));
            let data = {
                name: this.state.name,
                email: this.state.email,
                message: this.state.message
            }

            try {

                const resp = await axios.post(`https://turkishmasters.nirvanahotels.com.tr/test.php`, data)

                if (resp.status === 200) {
                    this.setState({
                        sendLoading: false,
                    }, () => {
                        if (resp.data === true) {

                            this.setState({
                                sendStatus: 1,
                            })

                        } else {
                            this.setState({
                                sendStatus: 2,
                            })
                        }
                    })

                }
            } catch (err) {
                console.error(err);
            }
        }
    }




    render() {

        const { data } = this.props;
        return (
            <main>
                <div className="slider">
                    <Menu data={data} />
                    <div className="contents">
                        <div className="logos">
                            <div className="item">
                                <div className="wstLogo">
                                    <img src="/Assets/img/sliderWst0.svg" alt="logo" />
                                </div>
                                <div className="dates"><i>{data.HomePage.slider[this.state.language].dates}</i></div>

                            </div>
                            <div className="item"><img src="/Assets/img/sliderWst1.svg" alt="logo" /></div>
                        </div>
                        <div className="getTicket">
                            <Link to="/Tickets">
                                {data.HomePage.slider[this.state.language].button}
                            </Link>
                        </div>
                    </div>

                    <Swiper
                        spaceBetween={50}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}

                        slidesPerView={1}

                    >
                        <SwiperSlide><img src="./Assets/img/slider1.jpg" /></SwiperSlide>
                        <SwiperSlide><img src="./Assets/img/slider1.jpg" /></SwiperSlide>
                    </Swiper>

                </div>
                <div className="countdownContent" style={{display:"none"}}>
                    <div className="countdown">
                        <div className="clockTime">{data.HomePage.countdown[this.state.language].title}</div>
                        <div className="clockParent">
                            <div className="clock" id="clock"></div>
                        </div>


                    </div>
                </div>
                <div className="events">
                    <div className="eventsTitle">
                        <div className="text">{data.HomePage.upcoming[this.state.language].title}</div>

                    </div>
                    <div className="eventList">
                        <div className="title">{data.HomePage.upcoming[this.state.language].detail}</div>
                        <div className="list">
                            {data.HomePage.upcoming[this.state.language].item.map((item, index) => (
                                <div className="item" key={index}>
                                    <div className="day">
                                        {item.day}
                                    </div>
                                    <div className="date">{item.date}</div>
                                </div>
                            ))}


                        </div>


                    </div>
                </div>
                <div className="contact">
                    <div className="contactBlur">
                        <div className="title">{data.HomePage.contact[this.state.language].title}</div>
                        <div className="forms">
                            <div className="inputGroup">
                                <div className="label">{data.HomePage.contact[this.state.language].name}</div>
                                <input type="text" className={this.state.nameError && "errorInputs"} name="name" onChange={this.setContact.bind(this)} />
                            </div>
                            <div className="inputGroup">
                                <div className="label">{data.HomePage.contact[this.state.language].email}</div>
                                <input type="email" className={this.state.emailError && "errorInputs"} name="email" onChange={this.setContact.bind(this)} />
                            </div>

                            <div className="inputGroup">
                                <div className="label">{data.HomePage.contact[this.state.language].message}</div>
                                <textarea name="message" className={this.state.messageError && "errorInputs"} onChange={this.setContact.bind(this)} rows="4"></textarea>
                            </div>
                            {this.state.sendStatus === 1 ?
                                <>
                                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                    </svg>
                                    <div className="titless">
                                        {this.state.language === "en" ?

                                            "Your message has been sent successfully." :
                                            "Mesajınız başarıyla gönderilmiştir."
                                        }
                                    </div>
                                </>
                                : this.state.sendStatus === 2 ?
                                    <>
                                        <div className="titless">
                                            {this.state.language === "en" ?

                                                "An error occurred while sending the message. Please send an e-mail to nirvanaturkishmasters@nirvanahotels.com.tr." :
                                                "Mesaj gönderilirken bir hata oluştu. Lütfen nirvanaturkishmasters@nirvanahotels.com.tr adresine eposta atabilirsiniz."
                                            }
                                        </div>

                                    </>
                                    : ""}
                            <div className="sendButton" onClick={this.sendMessage.bind(this)}>
                                {data.HomePage.contact[this.state.language].button}
                            </div>


                        </div>
                    </div>
                </div>
                {this.state.sendLoading &&
                    <div className='loadingss'>
                        Loading&#8230;
                    </div>
                }
            </main>

        );
    }
}
export default Home