import React from 'react';
import Headers from "./Components/Headers";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Footer from './Components/Footer';
import MeetSnookers from './Components/MeetSnookers'
import CosmosPerformanceHall from './Components/CosmosPerformanceHall'
import Sponsors from './Components/Sponsors'
import Gallery from './Components/Gallery'
import Tickets from './Components/Tickets'
import Fanzone from './Components/Fanzone'
import detectBrowserLanguage from 'detect-browser-language'
import data from './data.json';
function App() {

  const langCode = localStorage.getItem("langCode");
  if (!langCode) {
    localStorage.setItem("langCode", "en");
  }

  return (

    <Router>
      <div className="App">
        <Headers data={data}  />
        <Routes>
          <Route index path="/" element={<Home data={data} />} />
          <Route path="/MeetSnookers" element={<MeetSnookers data={data} />} />
          <Route path="/CosmosPerformanceHall" element={<CosmosPerformanceHall data={data} />} />
          <Route path="/Sponsors" element={<Sponsors data={data} />} />
          <Route path="/Gallery" element={<Gallery data={data} />} />
          <Route path="/Tickets" element={<Tickets data={data} />} />
          <Route path="/Fanzone" element={<Fanzone data={data} />} />
        </Routes>
      </div>
      <Footer data={data} />
    </Router>

  );
}

export default App;
