import React, { Component } from 'react';

import Menu from './Menu';


class Fanzone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem("langCode")
        }
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }







    render() {
        const { data } = this.props;
        return (
            <main>
                <div className="meetSnookersParent">
                    <Menu data={data} />
                    <div className="meetSnookers">
                        <div className='title'>
                            <div className="text">{data.fanzone[this.state.language].title}</div>
                        </div>
                        <div className='contents detailFanzone' dangerouslySetInnerHTML={{ __html: data.fanzone[this.state.language].detail }}>

                        </div>
                    </div>
                </div>

            </main>

        );
    }
}
export default Fanzone