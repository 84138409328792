import React, { Component } from 'react';


import Menu from './Menu';


class Tickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: localStorage.getItem("langCode")
        }
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    getAccoTicket = (e) => {
        window.open(e, '_blank');
    }

    getDailyTicket =(e)=> {
        window.open(e, '_blank');
    }




    render() {

        const { data } = this.props;
        return (
            <main>
                <div className="ticketsParent">
                    <Menu data={data} />
                    <div className="tickets">
                        <div className='title'>
                            <div className="text">{data.ticket[this.state.language].accoTitle}</div>
                        </div>
                        {data.ticket[this.state.language].items.map((item, index) => (
                            <div className='contents ticketContent mb30' key={index}>
                                <div className='datesParent'>
                                    <div className='dates'>
                                        <div className='day' onClick={this.getAccoTicket.bind(this, item.link)}>{item.days}</div>

                                    </div>
                                </div>
                                <div className='imgs'><img src={item.img} alt="acco" /></div>
                                <div className='detail'>
                                    <div className='title'>{item.title}</div>
                                    <div className='detailText'>{item.detail}</div>

                                </div>
                                <div className='biletix '>
                                    <div className='biletixButton' onClick={this.getAccoTicket.bind(this, item.link)}>
                                        {item.button}
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>

                <div className="ticketsParent">
                    <div className="tickets">
                        <div className='title'>
                            <div className="text" >{data.ticket[this.state.language].dailyTitle}</div>
                        </div>
                        {data.ticket[this.state.language].itemsDaily.map((item, index) => (
                            <div className='contents ticketContent2' key={index}>
                                <div className='datesParent'>
                                    <div className='dates'>
                                        <div className='day' onClick={this.getDailyTicket.bind(this,item.link)}>{item.days}</div>

                                    </div>
                                </div>
                                <div className='detail'>
                                    <div className='title'>{item.title}</div>
                                    <div className='detailText'>{item.detail}</div>
                                </div>
                                <div className='biletix'>
                                    <div className='biletixButton' onClick={this.getDailyTicket.bind(this,item.link)}>
                                        {item.button}
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </main>

        );
    }
}
export default Tickets